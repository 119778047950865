<template>
  <div class="sub-header">
    <div class="sub-header__wrap container flex">
      <div class="sub-header__left flex">
        <img class="sub-header__logo" src="@/assets/png/home-img-blue.png" alt="" />
        <div class="sub-header__info">
          <p class="sub-header__date">
            {{ days[date.getDay() - 1] }}, {{ date.getDate() }} {{ months[date.getMonth()] }}
            {{ date.getFullYear() }}
          </p>
          <h1 v-if="profileProducts" class="sub-header__title main-title">
            {{ text }}, {{ profileProducts.name }}
          </h1>
          <p class="sub-header__text">
            {{ motivation }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      date: new Date(),
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
      days: ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
    };
  },
  computed: {
    ...mapState(["profileProducts"]),
    text() {
      const time = new Date().getHours();
      if (time > 6 && time < 11) {
        return "Доброе утро";
      } else if (time >= 11 && time < 17) {
        return "Добрый день";
      } else if (time >= 17 && time < 22) {
        return "Добрый вечер";
      } else {
        return "Доброй ночи";
      }
    },
    motivation() {
      const list = [
        "Настоящее конкурентное преимущество Satti Logistics это Вы!",
        "Для победы недостаточно быть сильным. Вся команда должна верить в победу!",
        "“Если вы ни к чему не стремитесь, вы ничего не получите”. Зиг Зиглар",
        "“Нынче модно зарабатывать”. Нуржан Ниязалиев",
        "“Нет волшебства, чтобы мечта стала реальностью. Это требует решимости и упорного труда”. Колин Пауэлл",
        "“Чем усерднее вы работаете, тем более удачливым вы становитесь”. Томас Джефферсон",
        "“По возможности будьте добры. И это всегда возможно”. Далай Лама",
        "“Лучший показатель командной работы — это когда каждый работает отдельно для достижения общей цели”. Джеймс Кэш Пенни",
        "“Сконцентрируйтесь на эффективности, а не на работе”. Тим Феррис",
        "“Сила команды — в каждом участнике. Сила каждого участника в команде”. Фил Джексон",
      ];
      const index = Math.floor(Math.random() * 10);
      return list[index];
    },
  },
};
</script>

<style scoped>
.sub-header {
  padding: 25px 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
  box-sizing: border-box;
  height: 150px;
}
.sub-header__logo {
  height: 100px;
}
.sub-header__info {
  color: #acacac;
  margin-left: 20px;
}
.sub-header__date {
  font-size: 12px;
  line-height: 14px;
}
.sub-header__title {
  margin: 7px 0;
}
.sub-header__wrap {
  align-items: flex-end;
}
.sub-header__icon {
  margin-right: 10px;
}
</style>
